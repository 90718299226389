import React, { useState, useMemo } from 'react';
import { 
  Box, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  Typography,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Container,
  CircularProgress
} from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface Period {
  year: number;
  quarter: number;
  month: number;
  day: number;
  periodTS: number;
  periodName: string;
}

interface ProfitPeriod {
  period: Period;
  profit: number;
  equity: number;
}

interface TokenData {
  productWatchId: number;
  instrumentWatchId: number;
  start: number;
  symbol: string;
  watchDate: number;
  watchPriceBid: number;
  watchPriceAsk: number;
  profit: number;
  invested: number;
  profitPerc1Day: number;
  profitPerc1Week: number;
  profitPerc1Month: number;
  profitPercAll: number;
  risk: string;
  market: string;
  type: string;
  flagCreatePortfolio: number;
  profitPerc: number;
  profits: ProfitPeriod[];
}

const getTokenIcon = (id: number): string => {
  switch(id) {
    case 1:
    case 2:
      return '🦍';
    case 3:
      return '₿';
    case 4:
      return '🔷';
    default:
      return '⟠';
  }
};

export const TokensList = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<string[]>(["Все инвест-идеи"]);

  const { data: tokens, isLoading, error } = useQuery<TokenData[]>({
    queryKey: ['tokens'],
    queryFn: async () => {
      const { data } = await axios.get('https://mvp.finby.tech/api/v1/watch/read?user_key=d8b53b40-9abe-422e-80f6-db148aafe25b');
      return data.products;
    }
  });

  // Получаем уникальные значения risk из данных
  const riskFilters = useMemo(() => {
    const defaultFilters = ["Все инвест-идеи"];
    if (!tokens || !Array.isArray(tokens) || tokens.length === 0) return defaultFilters;
    
    const uniqueRisks = Array.from(new Set(tokens.map(token => token.risk))).filter(Boolean);
    return uniqueRisks.length > 0 ? [...defaultFilters, ...uniqueRisks] : defaultFilters;
  }, [tokens]);

  const handleFilterChange = (filter: string) => {
    if (filter === "Все инвест-идеи") {
      setSelectedFilters(["Все инвест-идеи"]);
    } else {
      setSelectedFilters([filter]);
    }
  };

  const handleTokenClick = (tokenId: number) => {
    navigate(`/tokens/${tokenId}`);
  };

  const filteredTokens = useMemo(() => {
    if (!tokens || !Array.isArray(tokens)) return [];
    
    return tokens.filter(token => {
      const matchesSearch = token.symbol.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesFilter = selectedFilters.includes("Все инвест-идеи") || token.risk === selectedFilters[0];
      return matchesSearch && matchesFilter;
    });
  }, [tokens, searchQuery, selectedFilters]);

  if (isLoading) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
          <Typography color="error">Произошла ошибка при загрузке данных</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container 
      maxWidth={false} 
      sx={{ 
        maxWidth: '1100px',
        px: { xs: 2, sm: 3 },
        py: { xs: 2, sm: 3 },
      }}
    >
      <Box>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mb: 3 
        }}>
          <TextField
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder=""
            variant="standard"
            hiddenLabel
            autoComplete="off"
            inputProps={{
              autoComplete: 'off',
              'aria-autocomplete': 'none',
              spellCheck: 'false'
            }}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '8px',
              width: '100%',
              '& .MuiInput-root': {
                backgroundColor: '#fff',
                borderRadius: '8px',
                borderBottom: '1px solid #E5E7EB',
                '&:before, &:after': {
                  display: 'none'
                },
                '& input': {
                  color: '#374151',
                  padding: '8px 0',
                  '&::placeholder': {
                    color: '#374151',
                    opacity: 1
                  }
                }
              },
              '& .MuiFormHelperText-root': {
                display: 'none'
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: '#6B7280' }} />
                </InputAdornment>
              ),
              endAdornment: searchQuery && (
                <InputAdornment position="end">
                  <IconButton 
                    size="small" 
                    onClick={() => setSearchQuery('')}
                    sx={{ 
                      color: '#6B7280',
                      backgroundColor: '#F3F4F6',
                      width: '24px',
                      height: '24px',
                      mr: 1,
                      '&:hover': {
                        backgroundColor: '#E5E7EB'
                      },
                      '&:focus': {
                        outline: 'none'
                      }
                    }}
                  >
                    <CloseIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Box>
            <Typography sx={{ 
              color: '#9CA3AF',
              mb: 2,
              fontWeight: 600,
              fontSize: '14px'
            }}>
              Фильтровать по инвест-идеям
            </Typography>
            <FormGroup row sx={{ gap: 2 }}>
              {riskFilters.map((filter) => (
                <FormControlLabel
                  key={filter}
                  control={
                    <Checkbox
                      checked={selectedFilters.includes(filter)}
                      onChange={() => handleFilterChange(filter)}
                      sx={{
                        color: '#D1D5DB',
                        '&.Mui-checked': {
                          color: '#3B82F6',
                        },
                      }}
                    />
                  }
                  label={filter}
                  sx={{
                    m: 0,
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                      color: '#374151'
                    }
                  }}
                />
              ))}
            </FormGroup>
          </Box>
        </Box>

        <TableContainer 
          component={Paper} 
          sx={{ 
            backgroundColor: '#fff',
            borderRadius: 2, 
            mt: 3,
            boxShadow: 'none',
            borderTop: '1px solid #E5E7EB',
            borderBottom: '1px solid #E5E7EB',
            '& .MuiTableCell-root': {
              borderLeft: 'none',
              borderRight: 'none'
            }
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#6B7280', fontWeight: 500 }}>#</TableCell>
                <TableCell sx={{ color: '#6B7280', fontWeight: 500 }}>Название токена</TableCell>
                <TableCell sx={{ color: '#6B7280', fontWeight: 500 }}>Инвест-идеи</TableCell>
                <TableCell sx={{ color: '#6B7280', fontWeight: 500 }}>Цена</TableCell>
                <TableCell sx={{ color: '#6B7280', fontWeight: 500 }}>Динамика роста</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTokens.map((token, index) => (
                <TableRow 
                  key={token.productWatchId}
                  onClick={() => handleTokenClick(token.productWatchId)}
                  sx={{ 
                    '&:hover': { backgroundColor: '#EFF6FF' },
                    cursor: 'pointer',
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                >
                  <TableCell sx={{ color: '#6B7280' }}>{index + 1}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box 
                        sx={{ 
                          width: 32, 
                          height: 32, 
                          borderRadius: '50%', 
                          backgroundColor: '#F3F4F6',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '20px'
                        }}
                      >
                        {getTokenIcon(index + 1)}
                      </Box>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          fontWeight: 500,
                          color: '#374151',
                          fontFamily: 'Oswald'
                        }}
                      >
                        {token.symbol}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                      <Chip 
                        label={token.risk} 
                        size="small" 
                        sx={{
                          backgroundColor: token.risk === "Умеренный" ? '#3B82F6' : '#EF4444',
                          color: 'white',
                          fontFamily: 'Oswald',
                          fontSize: '12px',
                          height: '24px'
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ color: '#374151', fontWeight: 500 }}>
                      {token.watchPriceBid.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'USD'
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{ 
                        color: token.profitPerc1Day >= 0 ? '#10B981' : '#EF4444',
                        fontWeight: 500
                      }}
                    >
                      {token.profitPerc1Day >= 0 ? '+' : ''}{token.profitPerc1Day.toFixed(2)}%
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'flex-end' }}>
                      <IconButton
                        size="small"
                        sx={{ 
                          color: '#6B7280',
                          backgroundColor: '#F3F4F6',
                          width: '32px',
                          height: '32px',
                          '&:hover': { 
                            color: '#374151',
                            backgroundColor: '#E5E7EB'
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          // Добавить в корзину
                        }}
                      >
                        <ShoppingCartOutlinedIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                      <IconButton
                        size="small"
                        sx={{ 
                          color: '#6B7280',
                          backgroundColor: '#F3F4F6',
                          width: '32px',
                          height: '32px',
                          '&:hover': { 
                            color: '#374151',
                            backgroundColor: '#E5E7EB'
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          // Добавить в избранное
                        }}
                      >
                        <BookmarkBorderOutlinedIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}; 